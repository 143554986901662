import { APP_ACTION_TYPES } from './app.types';

export const APP_INITIAL_STATE = {
  isSidebarOpen: false
};

export const appReducer = (
  state = APP_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case APP_ACTION_TYPES.SET_IS_SIDEBAR_OPEN:
      return { ...state, isSidebarOpen: payload };

    default:
      return state;
  }
};
