import { combineReducers } from 'redux';

import { appReducer } from './app/app.reducer';
import { userReducer } from './user/user.reducer';
import { ticketsReducer } from './tickets/tickets.reducer';
import { settingsReducer } from './settings/settings.reducer';

export const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  tickets: ticketsReducer,
  settings: settingsReducer
});
