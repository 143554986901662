import { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { loginToApp } from './store/user/user.action';

import { CircularProgress, Grid } from '@mui/material';

const Dashboard = lazy(() => import('./routes/dashboard/dashboard.component'));
const Login = lazy(() => import('./routes/login/login.component'));
const Protected = lazy(() => import('./routes/protected/protected.component'));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storageUser = localStorage.getItem('lucky-me-user');

    if (storageUser) {
      dispatch(loginToApp(JSON.parse(storageUser)));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Suspense
      fallback={
        <Grid container justifyContent="center" mt={10}>
          <CircularProgress />
        </Grid>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <Protected>
              <Dashboard />
            </Protected>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default App;
