import { TICKETS_ACTION_TYPES } from './tickets.types';

export const TICKETS_INITIAL_STATE = {
  tickets: [],
  isLoading: false,
  error: null
};

export const ticketsReducer = (
  state = TICKETS_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case TICKETS_ACTION_TYPES.FETCH_TICKETS_START:
    case TICKETS_ACTION_TYPES.UPLOAD_TICKETS_START:
      return { ...state, isLoading: true };

    case TICKETS_ACTION_TYPES.UPLOAD_TICKETS_FAILED:
    case TICKETS_ACTION_TYPES.FETCH_TICKETS_FAILED:
      return { ...state, isLoading: false, error: payload };

    case TICKETS_ACTION_TYPES.FETCH_TICKETS_SUCCESS:
      return { ...state, isLoading: false, tickets: payload };

    case TICKETS_ACTION_TYPES.UPLOAD_TICKETS_SUCCESS:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
