export const SETTINGS_ACTION_TYPES = {
  // api
  FETCH_SETTINGS_START: 'settings/FETCH_SETTINGS_START',
  FETCH_SETTINGS_SUCCESS: 'settings/FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILED: 'settings/FETCH_SETTINGS_FAILED',

  UPLOAD_SETTINGS_START: 'settings/UPLOAD_SETTINGS_START',
  UPLOAD_SETTINGS_SUCCESS: 'settings/UPLOAD_SETTINGS_SUCCESS',
  UPLOAD_SETTINGS_FAILED: 'settings/UPLOAD_SETTINGS_FAILED',

  // app
  UPDATE_SETTINGS_MAINTENANCE_MODE: 'settings/UPDATE_SETTINGS_MAINTENANCE_MODE',
  UPDATE_SETTINGS_MAINTENANCE_TEXT: 'settings/UPDATE_SETTINGS_MAINTENANCE_TEXT',
  UPDATE_SETTINGS_TERMS_CONDITIONS: 'settings/UPDATE_SETTINGS_TERMS_CONDITIONS'
};
