import { USER_ACTION_TYPES } from './user.types';

export const USER_INITIAL_STATE = {
  user: null,
  isLoggedIn: false,

  isLoading: false,
  error: null
};

export const userReducer = (
  state = USER_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.LOGIN_START:
      return { ...state, isLoading: true };

    case USER_ACTION_TYPES.LOGIN_FAILED:
      return { ...state, isLoading: false, error: payload };

    case USER_ACTION_TYPES.LOGIN_SUCCESS:
      return { ...state, isLoading: false, user: payload };

    case USER_ACTION_TYPES.SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: payload }

    default:
      return state;
  }
};
