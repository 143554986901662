import { SETTINGS_ACTION_TYPES } from './settings.types';

export const SETTINGS_INITIAL_STATE = {
  settings: {
    // field names are the same as from api
    maintenanceMode: false,
    maintenanceText: null,
    termsConditions: null
  },

  isLoading: false,
  error: null
};

export const settingsReducer = (
  state = SETTINGS_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    // api actions
    case SETTINGS_ACTION_TYPES.FETCH_SETTINGS_START:
    case SETTINGS_ACTION_TYPES.UPLOAD_SETTINGS_START:
      return { ...state, isLoading: true };

    case SETTINGS_ACTION_TYPES.FETCH_SETTINGS_FAILED:
    case SETTINGS_ACTION_TYPES.UPLOAD_SETTINGS_FAILED:
      return { ...state, isLoading: false, error: payload };

    case SETTINGS_ACTION_TYPES.FETCH_SETTINGS_SUCCESS:
      return { ...state, isLoading: false, settings: payload };

    case SETTINGS_ACTION_TYPES.UPLOAD_SETTINGS_SUCCESS:
      return { ...state, isLoading: false };

    default:
      return {
        ...state,

        // app actions
        settings: settings(state.settings, action)
      };
  }
};

const settings = (
  state = SETTINGS_INITIAL_STATE.settings,
  action = {}
) => {
  const { type, payload } = action;

  switch(type) {
    case SETTINGS_ACTION_TYPES.UPDATE_SETTINGS_MAINTENANCE_MODE:
      return { ...state, maintenanceMode: payload }

    case SETTINGS_ACTION_TYPES.UPDATE_SETTINGS_MAINTENANCE_TEXT:
      return { ...state, maintenanceText: payload }

    case SETTINGS_ACTION_TYPES.UPDATE_SETTINGS_TERMS_CONDITIONS:
      return { ...state, termsConditions: payload }

    default:
      return state;
  }
};
