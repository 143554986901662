import { USER_ACTION_TYPES } from './user.types';
import { USER_API_ENDPOINTS } from '../../api/endpoints/user.endpoints';
import { createAction } from '../../utils/reducer.utils';

// app
export const setIsLoggedIn = (isLoggedIn) => createAction(
  USER_ACTION_TYPES.SET_IS_LOGGED_IN,
  isLoggedIn
);

export const loginToApp = (user) => (dispatch) => {
  if (user && user.expirationTime > Date.now()) {
    dispatch(loginSuccess(user));
    dispatch(setIsLoggedIn(true));
  }
};

export const logoutFromApp = () => (dispatch) => {
  dispatch(loginSuccess(null));
  dispatch(setIsLoggedIn(false));

  localStorage.removeItem('lucky-me-user');
};

// api
const loginStart = () => createAction(
  USER_ACTION_TYPES.LOGIN_START
);

const loginFailed = (error) => createAction(
  USER_ACTION_TYPES.LOGIN_FAILED,
  error
);

export const loginSuccess = (user) => createAction(
  USER_ACTION_TYPES.LOGIN_SUCCESS,
  user
);

export const login = (user) => async (dispatch) => {
  dispatch(loginStart());

  try {
    const { data } = await USER_API_ENDPOINTS.login(user);

    dispatch(loginSuccess(data));
    dispatch(setIsLoggedIn(true));

    localStorage.setItem('lucky-me-user', JSON.stringify(data));
  } catch (error) {
    dispatch(loginFailed(error));
  }
};
