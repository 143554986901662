import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

client.interceptors.request.use(
  (config) => {
    const user = JSON.parse(
      localStorage.getItem('lucky-me-user')
    );

    if (user && user.accessToken)
      config.headers.Authorization = `Bearer ${user.accessToken}`;

    return config;
  }
);

export { client };
